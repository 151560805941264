const Treatment = {
prevent: require("../images copy/prevent-wellness.png"), 
detox: require("../images copy/detox.png"),
acupuncture: require("../images copy/acupuncture.png"), 
ayurveda: require("../images copy/ayurveda.png"), 
stemcell: require("../images copy/Regenerative Ma.png"), 
nutriton: require("../images copy/nutrition.png"), 
chiro: require("../images copy/Chiropractic.png"), 
chelation: require("../images copy/chelation.png"),
homeopath: require("../images copy/homeopathy.png"),
hypnotherapy:require("../images copy/hypno.png"),
herbalmedicine:require("../images copy/HERBALMEDICINE.png"),
meditation:require("../images copy/meditation.jpg"),
seo:require("../images copy/Untitled design.png")
};
export default Treatment;
